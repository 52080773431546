import {
  AnimationClip, AnimationMixer, HemisphereLight, PerspectiveCamera, Scene, WebGLRenderer,
  Clock, AnimationAction, LoopOnce, LoopRepeat, type AnimationMixerEventMap,
  type EventListener as ThreeEventListener, Raycaster, Vector2, Mesh, Object3D,
  type Intersection, type Object3DEventMap,
  MeshStandardMaterial,
  Texture,
} from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader, type GLTF } from "three/examples/jsm/loaders/GLTFLoader.js";
import stoneModel from "./stone.glb?url";
import { getRandomInt, sample } from "../utils";

enum ModelStatus {
  NotLoaded,
  Loading,
  Loaded,
  Error
}

const MIN_IDLE_TIMEOUT = 5000;
const MAX_IDLE_TIMEOUT = 30000;
const PAINT_INTERVAL = 0.001;

class StoneRenderer {

  private modelStatus: ModelStatus = ModelStatus.NotLoaded;
  private loader: GLTFLoader;
  private scene: Scene;
  private camera: PerspectiveCamera;
  controls: OrbitControls | null = null;
  width: number;
  height: number;
  private renderer: WebGLRenderer;
  private stone: GLTF | null = null;
  private mesh: Mesh | null = null;
  private texture: Texture | null = null;
  private originalTextureImage: ImageBitmap | null = null;
  private textureCanvas: HTMLCanvasElement | null = null;
  private textureContext: CanvasRenderingContext2D | null = null;
  private lastPaintTime: number = 0;
  private clock: Clock = new Clock();
  private raycaster: Raycaster = new Raycaster();
  private mouse: Vector2 = new Vector2();
  private mouseIntersects: Intersection<Object3D<Object3DEventMap>>[] = [];
  private cursor: Intersection<Object3D<Object3DEventMap>> | null = null;
  private animationMixer: AnimationMixer | null = null;
  private shakeAction: AnimationAction | null = null;
  private activeActions: AnimationAction[] = [];
  isShaking: boolean = false;
  isRotating: boolean = false;
  isOrbiting: boolean = false;
  isPainting: boolean = false;
  private idleTimeout: number = 0;
  private allowIdle: boolean = true;

  constructor(canvas: HTMLCanvasElement, initialWidth: number, initialHeight: number) {
    console.info('🗿 initializing stone renderer');

    // Model Loader
    this.loader = new GLTFLoader();

    // Renderer & Scene
    this.width = initialWidth;
    this.height = initialHeight;
    this.scene = new Scene();
    this.camera = new PerspectiveCamera(30, this.width / this.height, 0.1, 1000);
    this.renderer = new WebGLRenderer({ alpha: true, antialias: true, canvas });
    this.renderer.setSize(this.width, this.height, false);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setAnimationLoop(this.#draw);
    const light = new HemisphereLight(
      0xE0EEF4,    // skyColor - keeping light blue
      0xEDD4CD,    // groundColor - darker warm brown
      18           // intensity
    );
    this.scene.add(light);

    // @ts-expect-error for debugging
    window.stoneRenderer = this;
  }

  /**
   * 
   * @param modelPath - Path to the stone model, defaults to Steini
   * @returns Promise<boolean> - True if model was loaded successfully, false otherwise
   */
  loadStoneModel = async (modelPath: string = stoneModel): Promise<boolean> => {
    window.clearTimeout(this.idleTimeout);
    this.modelStatus = ModelStatus.Loading;
    try {
      // configure mesh
      const model = await this.loader.loadAsync(modelPath);
      this.stone = model;
      const { scene } = this.stone;
      this.mesh = scene.children[0] as Mesh;
      this.mesh.geometry.center();
      // configure texture as drawable canvas
      const material = this.mesh.material as MeshStandardMaterial;
      this.texture = material.map;
      if (!this.texture) throw new Error('🗿 could not find texture');
      this.textureCanvas = document.createElement('canvas');
      this.textureCanvas.width = this.texture.image.width;
      this.textureCanvas.height = this.texture.image.height;
      this.textureContext = this.textureCanvas.getContext('2d');
      if (!this.textureContext) throw new Error('🗿 could not initialize texture canvas context');
      this.textureContext.drawImage(this.texture.image, 0, 0);
      this.originalTextureImage = this.texture.image;
      this.texture.image = this.textureCanvas;
      material.needsUpdate = true;
      // configure camera
      this.camera.position.set(6, 3, 10);
      this.camera.lookAt(scene.position);
      this.camera.position.y -= 0.5;
      this.scene.add(scene);
      this.modelStatus = ModelStatus.Loaded;
      // configure orbit controls
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
      this.controls.dampingFactor = 0.05;
      this.controls.maxPolarAngle = Math.PI / 2;
      this.controls.enablePan = false;
      this.controls.enabled = false;
      this.controls.addEventListener("start", () => {
        this.isOrbiting = true;
      })
      this.controls.addEventListener("end", () => {
        this.isOrbiting = false;
      })
      this.controls.update();
      // set up animations
      this.animationMixer = new AnimationMixer(scene);
      this.animationMixer.addEventListener('finished', this.#handleAnimationFinished);
      const shakeClip = this.stone.animations.find((clip: AnimationClip) => clip.name === 'shake')
      if (shakeClip) {
        this.shakeAction = this.animationMixer.clipAction(shakeClip);
        this.shakeAction.setLoop(LoopRepeat, Infinity);
      } else {
        console.warn('🗿 could not find shake animation');
      }
      this.idleTimeout = window.setTimeout(this.#handleIdle, getRandomInt(MIN_IDLE_TIMEOUT, MAX_IDLE_TIMEOUT));
      this.playAppearAnimation();
      return true;
    } catch (e) {
      console.error('🗿 failed to load stone model', e);
      this.modelStatus = ModelStatus.Error;
      return false;
    }
  };

  resetTexture = () => {
    if (!this.originalTextureImage || !this.textureCanvas || !this.textureContext || !this.texture) return;
    this.textureContext.drawImage(this.originalTextureImage, 0, 0);
    this.texture.needsUpdate = true;
  }

  dispose = () => {
    console.info('🚮 disposing stone renderer');
    window.clearTimeout(this.idleTimeout);
    this.animationMixer?.stopAllAction();
    this.animationMixer?.removeEventListener('finished', this.#handleAnimationFinished);
    this.renderer.dispose();
  }

  listAnimations = () => {
    if (!this.stone) return [];
    return this.stone.animations.map((clip: AnimationClip) => clip.name);
  }

  playAnimation = (name: string) => {
    const target = this.stone?.animations.find((clip: AnimationClip) => clip.name === name);
    if (!target) throw new Error(`🗿 animation "${name}" not found`);
    if (!this.animationMixer) throw new Error('🗿 animation mixer not initialized');
    this.#playClip(target);
  }

  playIdleAnimation = () => {
    const idleAnimations = this.stone?.animations.filter((clip: AnimationClip) => clip.name.startsWith('idle'));
    if (!idleAnimations || idleAnimations.length === 0) return;
    const target = sample(idleAnimations);
    this.#playClip(target);
  }

  playAppearAnimation = () => {
    const appearAnimations = this.stone?.animations.filter((clip: AnimationClip) => clip.name.startsWith('appear'));
    if (!appearAnimations || appearAnimations.length === 0) return;
    const target = sample(appearAnimations);
    this.#playClip(target);
  }

  startShaking = () => {
    if (!this.shakeAction) return;
    this.shakeAction.enabled = true;
    this.shakeAction.play();
    this.shakeAction.fadeIn(0.5);
    this.isShaking = true;
  }

  stopShaking = () => {
    this.shakeAction?.fadeOut(0.5)
    this.isShaking = false;
  }

  startRotating = () => {
    this.isRotating = true;
  }

  stopRotating = () => {
    this.isRotating = false;
  }

  enableOrbitControls = () => {
    if (!this.controls) throw new Error('🗿 orbit controls not initialized, cannot enable');
    this.controls.enabled = true;
  }

  disableOrbitControls = () => {
    if (!this.controls) throw new Error('🗿 orbit controls not initialized, cannot disable');
    this.controls.reset();
    this.controls.enabled = false;
    this.controls.update();
  }

  beginBrushStroke = () => {
    console.debug('🧑‍🎨 starting brush stroke');
    this.isPainting = true;
  }

  endBrushStroke = () => {
    console.debug('🧑‍🎨 stopping brush stroke');
    this.isPainting = false;
  }

  enableIdleAnimations = () => {
    this.allowIdle = true;
  }

  disableIdleAnimations = () => {
    this.allowIdle = false;
  }

  setResolution = (width: number, height: number) => {
    const newAspect = width / height;
    this.width = width;
    this.height = height;
    this.renderer.setSize(width, height, false);
    if (this.camera.aspect !== newAspect) {
      console.info('🗿 updating camera aspect ratio');
      this.camera.aspect = newAspect;
      this.camera.updateProjectionMatrix();
    }
  };

  placeCursor = (canvasX: number, canvasY: number): boolean => {
    if (!this.mesh) return false;
    // Normalized Device Coordinates
    this.mouse.x = (canvasX / this.width) * 2 - 1;
    this.mouse.y = -(canvasY / this.height) * 2 + 1;
    this.raycaster.setFromCamera(this.mouse, this.camera);
    this.raycaster.intersectObject(this.mesh, false, this.mouseIntersects);
    if (this.mouseIntersects.length > 0) {
      console.log('🗿 mouse intersects', this.mouseIntersects);
      this.cursor = this.mouseIntersects[0];
      // -- just for testing, draw a red dot on the texture
      // const pt = this.cursor.uv as Vector2;
      // this.textureContext!.beginPath();
      // this.textureContext!.arc(pt.x * this.textureCanvas!.width, pt.y * this.textureCanvas!.height, 5, 0, Math.PI * 2);
      // this.textureContext!.fillStyle = 'red';
      // this.textureContext!.fill();
      // this.texture!.needsUpdate = true;
      // --
      this.mouseIntersects.length = 0;
      return true;
    }
    return false;
  }

  resetCursor = () => {
    this.cursor = null;
  }

  getStatus = () => {
    return this.modelStatus;
  }

  #handleIdle = () => {
    window.clearTimeout(this.idleTimeout);
    if (!this.isShaking && this.activeActions.length === 0 && this.allowIdle) {
      console.info('🗿 idle timeout reached, playing idle animation');
      this.playIdleAnimation();
    } else {
      console.info('🗿 idle timeout reached, but not idle, rescheduling');
    }
    this.idleTimeout = window.setTimeout(this.#handleIdle, getRandomInt(MIN_IDLE_TIMEOUT, MAX_IDLE_TIMEOUT));
  }

  #handleAnimationFinished: ThreeEventListener<AnimationMixerEventMap['finished'], "finished", AnimationMixer> = (e) => {
    this.activeActions = this.activeActions.filter((action: AnimationAction) => action !== e.action);
  }

  #draw = () => {
    if (!this.stone) return;
    if (this.animationMixer) this.animationMixer.update(this.clock.getDelta());
    if (this.isRotating) {
      this.scene.rotation.y = (this.scene.rotation.y + 0.001) % (Math.PI * 2);
    } else if (this.scene.rotation.y > 0) {
      this.scene.rotation.y -= 0.1;
    } else if (this.scene.rotation.y < 0) {
      this.scene.rotation.y = 0;
    }
    if (this.isPainting && this.cursor && this.clock.elapsedTime - this.lastPaintTime > PAINT_INTERVAL) {
      console.log('🧑‍🎨 painting');
      const pt = this.cursor.uv as Vector2;
      this.textureContext!.beginPath();
      this.textureContext!.arc(pt.x * this.textureCanvas!.width, pt.y * this.textureCanvas!.height, 5, 0, Math.PI * 2);
      this.textureContext!.fillStyle = 'red';
      this.textureContext!.fill();
      this.texture!.needsUpdate = true;
      this.lastPaintTime = this.clock.elapsedTime;
    }
    this.controls?.update();
    this.renderer.render(this.scene, this.camera);
  };

  #playClip = (clip: AnimationClip) => {
    if (!this.animationMixer) return;
    const action = this.animationMixer.clipAction(clip);
    this.activeActions.push(action);
    action.setLoop(LoopOnce, 1);
    action.reset();
    action.play();
  }

}

export default StoneRenderer;