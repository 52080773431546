<script lang="ts">
  // TEMPORARY - REMOVE THIS FILE AND USE Stone.svelte INSTEAD
  // ONCE ACTIONS ARE IMPLEMENTED
  import { onMount } from "svelte";
  import StoneRenderer from "./stone_renderer";
  import { fade, fly } from "svelte/transition";
  import { track } from "../../analytics/client";

  interface Props{
    hide?: boolean;
  }

  const MODE_OVERLAY: 0 = 0;
  const MODE_FOCUS: 1 = 1;
  const MODE_PAINT: 2 = 2;

  let container: HTMLDivElement | undefined;
  let canvas: HTMLCanvasElement | undefined;
  let stoneRenderer: StoneRenderer | undefined;

  let {hide = false} : Props = $props()
  let ready = $state(false);
  let mode = $state<number>(MODE_OVERLAY);
  let dragging = $state(false);
  let paintCursor = $state(false);
  let dragStartPoint = { x: 0, y: 0 };
  let canvasDimensions = $state(getOverlayDimensions());
  let hasMoved = false;
  let x = $state(window.innerWidth - getOverlayDimensions().width - 15);
  let y = $state(window.innerHeight - getOverlayDimensions().height - 15);
  let relX = 0;
  let relY = 0;

  // -- GENERAL --
  const getTransition = () => {
    if (dragging) {
      return "none";
    } else if (mode === MODE_FOCUS) {
      return "all 500ms ease";
    } else if (mode === MODE_PAINT) {
      return "all 0ms ease";
    } else {
      return "all 200ms ease";
    }
  };

  const getCursor = () => {
    switch (mode) {
      case MODE_OVERLAY:
        return dragging ? "grabbing" : "grab";
      case MODE_FOCUS:
        return "default";
      case MODE_PAINT:
        return paintCursor ? "crosshair" : "move";
    }
  };

  const getPointDistance = (x1: number, y1: number, x2: number, y2: number) => {
    return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
  };

  const exitMode = () => {
    switch (mode) {
      case MODE_FOCUS:
        exitFocusMode();
        break;
      case MODE_PAINT:
        exitPaintMode();
        break;
    }
  };

  // -- MODE: OVERLAY --
  function getOverlayDimensions() {
    // the ratio of the stone's width to its height
    const factor = 1.5;

    if (window.innerWidth > window.innerHeight) {
      const width = 337.5;
      return {
        width,
        height: width / factor,
      };
    } else {
      const width = 150;
      return {
        width,
        height: width / factor,
      };
    }
  }

  const alignStoneToBounds = () => {
    canvasDimensions = getOverlayDimensions();
    if (x < 0) {
      x = 0;
    }
    if (y < 0) {
      y = 0;
    }
    if (x + canvasDimensions.width > window.innerWidth) {
      x = window.innerWidth - canvasDimensions.width;
    }
    if (y + canvasDimensions.height > window.innerHeight) {
      y = window.innerHeight - canvasDimensions.height;
    }
  };

  const handleOverlayPointerDown = (e: PointerEvent) => {
    if (e.button !== 0 || !container) return;
    dragging = true;
    hasMoved = false;
    container.setPointerCapture(e.pointerId);
    relX = e.pageX - container.offsetLeft;
    relY = e.pageY - container.offsetTop;
    dragStartPoint = { x: e.pageX, y: e.pageY };
    e.stopPropagation();
    e.preventDefault();
  };

  const handleOverlayPointerMove = (e: PointerEvent) => {
    if (!dragging) return;
    if (!stoneRenderer?.isShaking) stoneRenderer?.startShaking();
    if (!hasMoved) hasMoved = true;
    x = e.pageX - relX;
    y = e.pageY - relY;
    e.stopPropagation();
    e.preventDefault();
  };

  const handleOverlayPointerUp = (e: PointerEvent) => {
    dragging = false;
    container?.releasePointerCapture(e.pointerId);
    if (hasMoved) {
      stoneRenderer?.stopShaking();
      alignStoneToBounds();
      const distance = getPointDistance(
        dragStartPoint.x,
        dragStartPoint.y,
        e.pageX,
        e.pageY,
      );
      if (distance < 10) {
        enterFocusMode();
      }
    } else {
      enterFocusMode();
    }
    e.stopPropagation();
    e.preventDefault();
  };

  // -- MODE: FOCUS --

  const getFocusDimensions = () => {
    // Get canvas dimensions from state
    const canvasAspectRatio = canvasDimensions.width / canvasDimensions.height;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    let width, height;

    // Scale to fit viewport while preserving ratio
    if (viewportWidth / viewportHeight > canvasAspectRatio) {
      height = viewportHeight;
      width = height * canvasAspectRatio;
    } else {
      width = viewportWidth;
      height = width / canvasAspectRatio;
    }

    // Center in viewport
    const x = (viewportWidth - width) / 2;
    const y = (viewportHeight - height) / 2;

    return { x, y, width, height };
  };

  const setFocusDimensions = () => {
    const dimensions = getFocusDimensions();
    x = dimensions.x;
    y = dimensions.y;
    canvasDimensions = { width: dimensions.width, height: dimensions.height };
  };

  const enterFocusMode = () => {
    console.info("🥌 entering stone focus mode");
    track("entered_stone_focus_mode");
    mode = MODE_FOCUS;
    const dimensions = getFocusDimensions();
    stoneRenderer?.setResolution(dimensions.width, dimensions.height);
    stoneRenderer?.stopShaking();
    x = dimensions.x;
    y = dimensions.y;
    canvasDimensions = { width: dimensions.width, height: dimensions.height };
    stoneRenderer?.startRotating();
  };

  const exitFocusMode = () => {
    console.info("🥌 exiting stone focus mode");
    mode = MODE_OVERLAY;
    stoneRenderer?.setResolution(
      canvasDimensions.width,
      canvasDimensions.height,
    );
    canvasDimensions = getOverlayDimensions();
    x = window.innerWidth - canvasDimensions.width - 15;
    y = window.innerHeight - canvasDimensions.height - 15;
    stoneRenderer?.stopRotating();
  };

  const handleFocusPointerUp = (e: PointerEvent) => {
    if (e.button !== 0 || !container || !stoneRenderer) return;
    const canvasX = e.clientX - x;
    const canvasY = e.clientY - y;
    if (!stoneRenderer.placeCursor(canvasX, canvasY)) {
      exitFocusMode();
    }
    stoneRenderer.resetCursor();
  };

  // -- MODE: PAINT --
  const exitPaintMode = () => {
    console.info("🎨 exiting stone paint mode");
    mode = MODE_OVERLAY;
    stoneRenderer?.enableIdleAnimations();
    stoneRenderer?.disableOrbitControls();
    enterFocusMode();
  };

  const handlePaintPointerDown = (e: PointerEvent) => {
    if (
      e.button !== 0 ||
      !stoneRenderer ||
      !container ||
      stoneRenderer.isOrbiting
    )
      return;
    container.setPointerCapture(e.pointerId);
    stoneRenderer.beginBrushStroke();
  };

  const handlePaintPointerUp = (e: PointerEvent) => {
    if (!stoneRenderer || !container) return;
    container.releasePointerCapture(e.pointerId);
    stoneRenderer.endBrushStroke();
  };

  const handlePaintPointerMove = (e: PointerEvent) => {
    if (!stoneRenderer) return;
    const canvasX = e.clientX - x;
    const canvasY = e.clientY - y;
    let cursorOnStone = false;
    if (
      x >= 0 &&
      x <= canvasDimensions.width &&
      y >= 0 &&
      y <= canvasDimensions.height
    ) {
      cursorOnStone = stoneRenderer.placeCursor(canvasX, canvasY);
    }
    if (
      stoneRenderer.controls &&
      stoneRenderer.controls?.enabled &&
      !stoneRenderer.isOrbiting &&
      cursorOnStone
    ) {
      // Disable controls when cursor is on stone and not currently orbiting
      stoneRenderer.controls.enabled = false;
    } else if (
      stoneRenderer.controls &&
      !stoneRenderer.controls?.enabled &&
      !cursorOnStone
    ) {
      // Enable controls when cursor is not on stone
      stoneRenderer.controls.enabled = true;
    }
    paintCursor = cursorOnStone;
  };

  // -- EVENT HANDLERS --

  const handlePointerDown = (e: PointerEvent) => {
    switch (mode) {
      case MODE_OVERLAY:
        handleOverlayPointerDown(e);
        break;
      case MODE_FOCUS:
        break;
      case MODE_PAINT:
        handlePaintPointerDown(e);
        break;
    }
  };

  const handlePointerMove = (e: PointerEvent) => {
    switch (mode) {
      case MODE_OVERLAY:
        handleOverlayPointerMove(e);
        break;
      case MODE_FOCUS:
        break;
      case MODE_PAINT:
        handlePaintPointerMove(e);
        break;
    }
  };

  const handlePointerUp = (e: PointerEvent) => {
    switch (mode) {
      case MODE_OVERLAY:
        handleOverlayPointerUp(e);
        break;
      case MODE_FOCUS:
        handleFocusPointerUp(e);
        break;
      case MODE_PAINT:
        handlePaintPointerUp(e);
        break;
    }
  };

  const handleWindowResize = () => {
    switch (mode) {
      case MODE_OVERLAY:
        alignStoneToBounds();
        break;
      case MODE_FOCUS:
        setFocusDimensions();
        break;
    }
  };

  onMount(() => {
    if (canvas) {
      stoneRenderer = new StoneRenderer(
        canvas,
        canvasDimensions.width,
        canvasDimensions.height,
      );
      stoneRenderer.loadStoneModel().then(() => {
        ready = true;
      });
      window.addEventListener("resize", handleWindowResize);
      return () => {
        stoneRenderer?.dispose();
        window.removeEventListener("resize", handleWindowResize);
      };
    }
  });
</script>

{#if mode >= MODE_FOCUS}
  <div
    aria-hidden="true"
    transition:fade={{ duration: 500 }}
    class="backdrop"
    onclick={exitMode}
  ></div>
{/if}

{#if mode === MODE_FOCUS}
  <div transition:fly={{ duration: 500, y: -100 }} class="text">
    The stone appears a little quieter than usual. <br />
    Seems like it's still getting used to its new surroundings.
  </div>
{/if}

<div
  bind:this={container}
  onpointerdown={handlePointerDown}
  onpointermove={handlePointerMove}
  onpointerup={handlePointerUp}
  class="stone-container"
  class:hide
  style={`
  --x: ${x}px;
  --y: ${y}px;
  --canvas-width: ${canvasDimensions.width}px;
  --canvas-height: ${canvasDimensions.height}px;
  --cursor: ${getCursor()};
  --transition: ${getTransition()};
  --pointer-events: ${ready ? "auto" : "none"};
 `}
>
  <canvas bind:this={canvas}> </canvas>
</div>

<style>
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    background: rgba(0, 0, 0, 0.95);
    z-index: 999;
  }
  .stone-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    cursor: var(--cursor);
    top: var(--y);
    left: var(--x);
    transition: var(--transition);
    pointer-events: var(--pointer-events);
    user-select: none;
    --webkit-user-select: none;
  }
  .hide{
    transform: scale(0);
    pointer-events: none;
  }
  canvas {
    width: var(--canvas-width);
    height: var(--canvas-height);
    transition: var(--transition);
  }
  .text {
    position: fixed;
    z-index: 1001;
    top: 0;
    left: 0;
    width: 100vw;
    text-align: center;
    color: white;
    font-size: 18px;
    text-align: center;
    padding: calc(var(--main-margin) * 2);
  }
</style>
